import { InstallmentStatuses } from '../../../interfaces/payments';

import { FiltersExportReport } from './types';

export const statusesOptions = Object
  .values(InstallmentStatuses)
  .map((value, i) => ({ id: String(i), name: value }));

export const initialValues: FiltersExportReport = {
  courses: [],
  installmentStatuses: [],
  paymentDateFrom: null,
  paymentDateTo: null,
  students: [],
};
