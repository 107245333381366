import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUsers } from '../../contexts/users/context';
import { useMessageModal } from '../../contexts/messageModal/context';

import Layout from '../../components/Layouts/Layout';
import ControlledTextField from '../../components/Inputs/ControlledTextField';
import ControlledAutocomplete from '../../components/Inputs/ControlledAutocomplete';
import LoadingComponent from '../../components/Controls/LoadingComponent';

import { ROLES, rolesDictionary } from '../../helpers/roles';
import {
  documentTypes,
  hospitals,
  phoneTypes,
  specialities,
} from '../../helpers/optionsInput';
import rulesConstants from '../../helpers/rulesConstants';

import { OptionInterface } from '../../interfaces/inputs';
import { CreateUserInterface, UpdateUserInterface, UserFormInterface } from '../../interfaces/user';

const classes = {
  buttonsContainer: {
    mt: 2,
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'center', md: 'end' },
    gap: 3,
  },
};

const UserManagement = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    createUser, fetchUserById, removeUser, updateUser,
  } = useUsers();
  const { openMessageModal } = useMessageModal();

  const userId = useMemo<string | undefined>(() => state?.userId, [state]);
  const isViewEdition = Boolean(userId);

  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(Boolean(userId));
  const [isSendingData, setIsSendingData] = useState<boolean>(false);

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<UserFormInterface>({
    defaultValues: useMemo(() => ({
      document: '',
      documentType: documentTypes[0].id,
      email: '',
      first_name: '',
      hospital: '',
      last_name: '',
      paymentEmail: '',
      phoneNumber: '',
      phoneType: phoneTypes[0].id,
      role: ROLES.TEACHER as 'teacher',
      specialty: '',
    }), []),
  });

  const isStudent = getValues('role') === ROLES.STUDENT;

  const typesUser: OptionInterface[] = [
    { id: ROLES.TEACHER, name: rolesDictionary.teacher },
    { id: ROLES.ADMIN, name: rolesDictionary.admin },
    isViewEdition && isStudent && { id: ROLES.STUDENT, name: rolesDictionary.student },
  ];

  const handleCreateUser = useCallback(async (user: CreateUserInterface): Promise<void> => {
    const userCreated = await createUser(user);
    if (userCreated) {
      openMessageModal({
        title: '¡Usuario creado con éxito!',
        message: 'Se envió un email de confirmación para poder activar la cuenta',
        variant: 'success',
        primaryButton: {
          text: 'Aceptar',
          onClick: () => { navigate('/users'); },
        },
        closeButton: () => { navigate('/users'); },
      });
    }
  }, [createUser, openMessageModal, navigate]);

  const handleUpdateUser = useCallback(async (user: UpdateUserInterface): Promise<void> => {
    const userUpdated = await updateUser(userId, user);

    if (userUpdated) {
      openMessageModal({
        title: '¡Usuario actualizado con éxito!',
        variant: 'success',
        primaryButton: {
          text: 'Aceptar',
          onClick: () => navigate('/users'),
        },
      });
    }
  }, [navigate, openMessageModal, updateUser, userId]);

  const onSubmit = async (user: UserFormInterface): Promise<void> => {
    setIsSendingData(true);

    if (isViewEdition) {
      const {
        role, ...userToUpdate
      } = user;
      handleUpdateUser(userToUpdate);
    } else {
      handleCreateUser(user);
    }

    setIsSendingData(false);
  };

  useEffect(() => {
    const getUserById = async (): Promise<void> => {
      setIsFetchingUser(true);
      const user = await fetchUserById(userId);

      setValue('role', (user.role as 'teacher' | 'admin' | 'student'));
      setValue('first_name', user.first_name);
      setValue('last_name', user.last_name);
      setValue('email', user.email);
      setValue('paymentEmail', user.paymentEmail);
      setValue('document', user.document);
      setValue('documentType', user.documentType);
      setValue('phoneNumber', user.phoneNumber);
      setValue('phoneType', user.phoneType);
      setValue('specialty', user.specialty);
      setValue('hospital', user.hospital);

      setIsFetchingUser(false);
    };

    if (userId) {
      getUserById();
    }

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const onClickRemoveUser = async (): Promise<void> => {
    openMessageModal({
      title: '¿Estás seguro/a que querés eliminar este usuario?',
      variant: 'infoError',
      message: 'Recordá que no podés deshacer esta acción, se eliminará todo el contenido',
      primaryButton: {
        text: 'Si, eliminar',
        onClick: async () => {
          await removeUser(userId);
          navigate('/users');
        },
      },
      secondaryButton: {
        text: 'Volver',
      },
      centerText: true,
    });
  };

  return (
    <Layout>
      <>
        <LoadingComponent loading={isFetchingUser} loadingSize={100} center>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography variant="h1" color="primary.main" mb={5}>
              {isViewEdition ? 'Edición de usuarios' : 'Alta de usuarios'}
            </Typography>
            <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledAutocomplete
                  control={control}
                  fieldName="role"
                  fieldLabel="Tipo de usuario"
                  options={typesUser}
                  rules={rulesConstants.select({ fieldLabel: 'Tipos de usuario' })}
                  disabled={isViewEdition}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledTextField
                  control={control}
                  fieldName="first_name"
                  fieldLabel="Nombre"
                  rules={rulesConstants.textfield({ fieldLabel: 'Nombre' })}
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledTextField
                  control={control}
                  fieldName="last_name"
                  fieldLabel="Apellido"
                  rules={rulesConstants.textfield({ fieldLabel: 'Apellido' })}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledTextField
                  control={control}
                  fieldName="email"
                  fieldLabel="Email"
                  rules={rulesConstants.emailTextfield({ fieldLabel: 'Email' })}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledTextField
                  control={control}
                  fieldName="paymentEmail"
                  fieldLabel="Email de pago"
                  rules={rulesConstants.emailTextfield({
                    fieldLabel: 'Email',
                    isRequired: !!watch('paymentEmail')?.length,
                  })}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledAutocomplete
                  control={control}
                  fieldName="documentType"
                  fieldLabel="Tipo de documento"
                  options={documentTypes}
                  rules={rulesConstants.select({ fieldLabel: 'Tipo de documento' })}
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledTextField
                  control={control}
                  endAdornment={(
                    <Tooltip
                      disableFocusListener
                      placement="left"
                      title="Solo puede ingresar números."
                    >
                      <IconButton color="primary" aria-label="Solo puede ingresar números." component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                        <HelpIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  fieldLabel="Número de documento"
                  fieldName="document"
                  rules={rulesConstants.textfield({ fieldLabel: 'Número de documento' })}
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledAutocomplete
                  control={control}
                  fieldName="phoneType"
                  fieldLabel="Tipo de teléfono"
                  options={phoneTypes}
                  rules={rulesConstants.textfield({ fieldLabel: 'Tipo de teléfono' })}
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                <ControlledTextField
                  control={control}
                  endAdornment={(
                    <Tooltip
                      disableFocusListener
                      placement="left"
                      title="El número debe contener al menos 10 dígitos"
                    >
                      <IconButton color="primary" aria-label="El número debe contener al menos 10 dígitos" component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                        <HelpIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  fieldLabel="Número de teléfono"
                  fieldName="phoneNumber"
                  rules={rulesConstants.phoneNumberValidation({ fieldLabel: 'Número de teléfono' })}
                  type="number"
                />
              </Grid>
            </Grid>
            {isViewEdition && isStudent && (
              <Grid container columnSpacing={10} mb={{ xs: 0, sm: 4 }}>
                <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                  <ControlledAutocomplete
                    control={control}
                    fieldName="specialty"
                    fieldLabel="Especialidad"
                    options={specialities}
                    rules={rulesConstants.textfield({ fieldLabel: 'Especialidad' })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} mb={{ xs: 4, sm: 0 }}>
                  <ControlledAutocomplete
                    control={control}
                    fieldName="hospital"
                    fieldLabel="Hospital"
                    options={hospitals}
                    rules={rulesConstants.textfield({ fieldLabel: 'Hospital' })}
                  />
                </Grid>
              </Grid>
            )}
            <Box sx={classes.buttonsContainer}>
              <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
                Volver
              </Button>
              <Button variant="contained" type="submit" disabled={!formState.isValid} color="primary">
                {isViewEdition ? 'Guardar' : 'Dar de alta'}
              </Button>
              {isViewEdition && (
                <Button variant="outlined" color="error" onClick={onClickRemoveUser}>
                  Eliminar
                </Button>
              )}
            </Box>
          </Box>
        </LoadingComponent>
        <Backdrop open={isSendingData}>
          <CircularProgress size={80} thickness={5} />
        </Backdrop>
      </>
    </Layout>
  );
};

export default React.memo(UserManagement);
